import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'


const FoodTipsView = ({
  item=false,
  setOpen,
  ...props
}) => {

  const map_keys = {
      "gramsOfProteinPer100Grams": "Protein",
      "gramsOfCarbohydratePer100Grams": "Carbohydrate",
      "gramsOfFatPer100Grams": "Fat",
      "gramsOfMonounsaturatedFatPer100Grams": "Monounsaturated Fat",
      "gramsOfPolyunsaturatedFatPer100Grams": "Polyunsaturated Fat",
      "gramsOfSaturatedFatPer100Grams": "Saturated Fat",
      "gramsOfSugarPer100Grams": "Sugar",
      "gramsOfFiberPer100Grams": "Fiber",
      "microgramsOfVitaminAPer100Grams": "Vitamin A micrograms",
      "microgramsOfVitaminDPer100Grams": "Vitamin D micrograms",
      "milligramsOfVitaminCPer100Grams": "Vitamin C milligrams",
      "milligramsOfIronPer100Grams": "Iron milligrams",
      "milligramsOfCalciumPer100Grams": "Calcium milligrams",
      "gramsOfSaltPer100Grams": "Salt",
      "gramsOfVegetablesPer100Grams": "Vegetables",
      "gramsOfFruitsPer100Grams": "Fruits",
  }

  return (
    <Transition.Root show={item?true:false} as={Fragment}>
      <Dialog as="div" className="absolute z-[10] inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-md">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="bg-bluegreen py-6 px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white"> {item.name} </Dialog.Title>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          className="rounded-md bg-bluegreen text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>

                  </div>
                  <div className="relative flex-1 py-6 px-4 sm:px-6">
                    {item && item.data && <div className="absolute inset-0 py-6 px-4 sm:px-6">
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Kcal</dt>
                                <dd className="text-sm font-medium text-gray-500">{item.kcal100G}</dd>
                              </div>

                              {Object.keys(item.data.nutrition).map((key, index) => {
                                  return key in map_keys && <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6" key={index}>
                                    <dt className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{map_keys[key]}</dt>
                                    <dd className="text-sm font-medium text-gray-500">{item.data.nutrition[key]}</dd>
                                  </div>
                              })}

                              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Allergies</dt>
                                <dd className="text-sm font-medium text-gray-500">{item.data['allergens'].join(', ')}</dd>
                              </div>

                              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">Barcode</dt>
                                <dd className="text-sm font-medium text-gray-500">{item.data['barcode']}</dd>
                              </div>
                            </dl>
                          </div>
                    </div>}

                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default FoodTipsView;
