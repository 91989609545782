import React from 'react'
import { NavLink } from 'react-router-dom'
import { useBreadcrumbs } from '../container/BreadcrumbsContainer'
import { HomeIcon, ChevronRightIcon } from '@heroicons/react/solid'

export default function Breadcrumbs() {
  const { breadcrumbs } = useBreadcrumbs()

  return (
    <div className="bg-white border-y border-gray-200 py-3 mb-6">
      <nav className="bg-white flex px-6" aria-label="Breadcrumb">
        <ol className="w-full mx-auto flex space-x-4">
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <li key={match.pathname}>
              {match.pathname === '/' ? (
                <div>
                  <NavLink to={match.pathname} className="text-gray-400 hover:text-gray-500">
                    <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                    <span className="sr-only">{breadcrumb}</span>
                  </NavLink>
                </div>
              ) : (
                <div className="flex items-center">
                  <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <NavLink className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700" to={match.pathname}>
                    {breadcrumb}
                  </NavLink>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    </div>
  )
}
