import { useSearchParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

const FOOD_PRODUCTS = gql`
  query GetFoodProducts(
    $filter: FoodProductFilter
    $pagesize: Int
    $offset: Int
    $orderBy: [FoodProductsOrderBy!]
  ) {
    foodProducts(
      filter: $filter
      first: $pagesize
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        id
        name
        kcal100G
        protein100G
        carbs100G
        fat100G
        saturatedFat100G
        sugar100G
        fiber100G
        calcium100G
        veg100G
        fruits100G
        nuts100G
        isHeartFriendly
        createdAt
        status
        trashed
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

function getFilter(searchParams) {
  const filter = {}

  if (searchParams.has('fullText')) {
    filter['fullText'] = { matches: searchParams.get('fullText') }
  }

  if (searchParams.has('trashed')) {
    filter['trashed'] = { equalTo: searchParams.get('trashed') === 'true' }
  }

  if (searchParams.has('isHeartFriendly')) {
    filter['isHeartFriendly'] = { equalTo: searchParams.get('isHeartFriendly') === 'true' }
  }

  return Object.keys(filter).length === 0 ? undefined : filter
}

export function useFoodProducts(pagesize) {
  const [searchParams] = useSearchParams()
  const offset = +searchParams.get('offset') || 0
  const orderBy = searchParams.get('orderBy') || 'CREATED_AT_DESC'
  const filter = getFilter(searchParams)

  const result = useQuery(FOOD_PRODUCTS, {
    variables: { filter, pagesize, offset, orderBy },
    nextFetchPolicy: 'cache-and-network',
  })

  const foodProducts = result.data?.foodProducts?.nodes ?? []
  const { totalCount = 0 } = result.data?.foodProducts ?? {}

  return { ...result, foodProducts, totalCount }
}
