import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { nb } from 'date-fns/locale'
import Loading from '../../layout/Loading'
import { useBreadcrumbs } from '../../../components/container/BreadcrumbsContainer'
import FoodProductDelete from './partials/FoodProductDelete'
import FoodProductForm from './partials/FoodProductForm'
import ProductsWithSimilarName from './partials/ProductsWithSimilarName'
import FoodProductUnits from './partials/FoodProductUnits'
import FoodProductBarcodes from './partials/FoodProductBarcodes'

const FIELDS = [
  'trashed',
  'name',
  'kcal100G',
  'fat100G',
  'monounsaturatedFat100G',
  'polyunsaturatedFat100G',
  'saturatedFat100G',
  'carbs100G',
  'sugar100G',
  'fiber100G',
  'protein100G',
  'salt100G',
  'vitaminA100G',
  'vitaminD100G',
  'vitaminC100G',
  'calcium100G',
  'iron100G',
  'veg100G',
  'fruits100G',
  'nuts100G',
  'alcohol100G',
  'isHeartFriendly',
  'allergens',
  'missingAllergenInfo',
]

export const GET_FOOD_PRODUCT = gql`
  query GetFoodProduct($id: Int!) {
    foodProduct(id: $id) {
      id
      createdAt
      ${FIELDS.join('\n')}
      foodUnitsByIngredientId {
        nodes {
          id
          unit {
            id
            unit
            gramsPerUnit
          }
        }
      }
      barcodesByProductId {
        nodes {
          id
          barcode
          data
        }
      }
    }
  }
`


export default function FoodProduct() {
  const { t } = useTranslation()
  const params = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const { setBreadcrumbs } = useBreadcrumbs()
  const variables = { id: Number(params.id) }
  const { loading, data } = useQuery(GET_FOOD_PRODUCT, { variables })
  const { foodProduct } = data ?? {}
  const foodProductName = foodProduct?.name ?? ' '

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  useEffect(() => {
    setBreadcrumbs([
      { path: '/foodProducts/:id', breadcrumb: foodProductName },
    ])
    return function cleanup() {
      setBreadcrumbs([
        { path: '/foodProducts/:id', breadcrumb: ' ' },
      ])
    }
  }, [foodProductName, setBreadcrumbs, t])

  if (loading) return <Loading />

  return (
    <>
      {/* info header */}
      <div className="mt-6 max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-6">
        <div className="flex items-center space-x-5">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">{foodProduct.name}</h1>
            <p className="text-sm font-medium text-gray-500">
              {t('Created at')} <time dateTime={foodProduct.createdAt} className="text-gray-900">{format(new Date(Date.parse(foodProduct.createdAt)), 'MMMM d, yyyy HH:ii:ss', { locale: nb })}</time>
            </p>
          </div>
        </div>
        <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
          <button
            type="button"
            className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            onClick={open}
          >
            {t('Delete food product')} ...
          </button>
          {isOpen && (
            <FoodProductDelete foodProduct={foodProduct} close={close} />
          )}
        </div>
      </div>
      {/* 3 column form wrapper */}
      <div className="mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 pb-16">
        {/* Left sidebar (2 cols) */}
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <section aria-labelledby="food-product-title">
            <div className="bg-white shadow sm:rounded-lg">
              <FoodProductForm foodProduct={foodProduct} fields={FIELDS} />
            </div>
          </section>
        </div>
        {/* Right sidebar (1 col) */}
        <div className="lg:col-start-3 lg:col-span-1">
          <ProductsWithSimilarName foodProduct={foodProduct} />
          <FoodProductUnits foodProduct={foodProduct} />
          <FoodProductBarcodes foodProduct={foodProduct} />
        </div>
      </div>
    </>
  )
}
