import React from 'react'
import { Fragment, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { NavLink } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import {
  AcademicCapIcon,
  HomeIcon,
  ShoppingCartIcon,
  LibraryIcon,
  ClipboardListIcon,
  XIcon,
  DocumentTextIcon,
} from '@heroicons/react/outline'

import Logo from '../../static/logo.svg'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }


const sections = [
  {
    name: null,
    navigation: [
      { name: 'Dashboard', href: '/', exact:true, icon: HomeIcon, current: true },
    ]
  },
  {
    name: 'Mat',
    navigation: [
      { name: 'Matvarer', href: '/foodProducts', exact:true, icon: ShoppingCartIcon, current: false },
      { name: 'Tips', href: '/foodTips', exact:true, icon: LibraryIcon, current: false },
      { name: 'Recipes', href: '/recipes', exact:true, icon: ClipboardListIcon, current: false },
      { name: 'Categories', href: '/categories', exact:true, icon: DocumentTextIcon, current: false },
    ]
  },
  {
    name: 'Kurs',
    navigation: [
      { name: 'Instructors', href: '/instructors', exact:true, icon: AcademicCapIcon, current: false },
    ]
  }



]

function SideBarNav(){
    return (
      <div className="flex flex-col flex-grow bg-coolgrey pt-5 pb-4 overflow-y-auto">
      <div className="grid grid-cols-1 items-center  text-white">
            <img src={Logo} className="block w-full px-4" alt="" />
            <div className="text-2xl f-full px-4  font-bold tracking-[.5em] text-linkturquoise pr-1">Central</div>

      </div>
      <nav className="mt-5 flex-1 flex flex-col divide-y divide-mediumgrey overflow-y-auto" aria-label="Sidebar">
      {sections.map((section, idx) => (
      <div key={`section-${idx}`} className="px-2 pb-2 space-y-1">
        {section.name &&   <div className="relative mx-2 my-2">

          <div className=" ">
            <span className="pr-2 bg-coolgrey text-sm text-darkblue">{section.name}</span>
          </div>
        </div>

        }
        {section.navigation.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              end={item.exact}
              className={({isActive}) => classNames(
                isActive ? ' text-white bg-darkblue ' : 'text-darkblue hover:text-coolgrey hover:bg-darkblue',
                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
              )}
            >
              <item.icon className="mr-3 h-6 w-6 " aria-hidden="true" />
              {item.name}
            </NavLink>
          ))}
        </div>
      ))}
      </nav>
    </div>
    )
}




export default function Sidebar({sidebarOpen, setSidebarOpen}) {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0)
    setSidebarOpen(false)
   },[navigate, setSidebarOpen])


return <>
   <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-300"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-300"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-darkblue">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-300"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-300"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>

               <SideBarNav />
               </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
         <SideBarNav />
        </div>



      </>

}
