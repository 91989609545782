import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useApolloClient } from '@apollo/react-hooks'
import { useMutation } from '@apollo/client'
import { SAVE_FOOD_PRODUCT } from './FoodProductForm'
import { GET_UNIT, CREATE_FOOD_UNIT } from './FoodProductUnits'
import { Dialog, Transition } from '@headlessui/react'
import { ShoppingCartIcon, XIcon } from '@heroicons/react/outline'
import LoadingIcon from '../../../atom/LoadingIcon'

const initialState = {
  name: '',
  trashed: true,
  kcal100G: 0,
  fat100G: 0,
  monounsaturatedFat100G: 0,
  polyunsaturatedFat100G: 0,
  saturatedFat100G: 0,
  carbs100G: 0,
  sugar100G: 0,
  fiber100G: 0,
  protein100G: 0,
  salt100G: 0,
  vitaminA100G: 0,
  vitaminC100G: 0,
  vitaminD100G: 0,
  calcium100G: 0,
  iron100G: 0,
  veg100G: 0,
  fruits100G: 0,
  nuts100G: 0,
  alcohol100G: 0,
  allergens: [],
  missingAllergenInfo: true,
  isHeartFriendly: false,
}

export default function FoodProductAdd({ close }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [data, setData] = useState(initialState)
  const [addFoodProduct, { loading }] = useMutation(SAVE_FOOD_PRODUCT)
  const [createFoodUnit, resultCreateFoodUnit] = useMutation(CREATE_FOOD_UNIT)
  const client = useApolloClient()

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const getUnitResult = await client.query({
      query: GET_UNIT,
      variables: {
        filter: {
          unit: { equalTo: 'g' },
          gramsPerUnit: { equalTo: 1 },
        },
      },
    })
    const unitId = getUnitResult.data?.units?.nodes[0]?.id
    const refetchQueries = ['GetFoodProducts']
    const input = { foodProduct: { ...data } }
    const variables = { input }
    const result = await addFoodProduct({ variables, refetchQueries })
    const { id } = result.data.createOrUpdateFoodProduct.foodProduct
    await createFoodUnit({ variables: {
      input: {
        foodUnit: { ingredientId: id, unitId },
      },
    }})
    close()
    navigate(`/foodProducts/${id}`)
  }

  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-20 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <form method="post" onSubmit={handleSubmit}>
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={close}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ShoppingCartIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="w-full mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {t('Add new food product')}
                    </Dialog.Title>
                    <div className="w-full mt-3 mb-2">
                      <p className="mb-3 text-sm text-gray-500">
                        {t('You will be able to edit the food product in the next screen.')}
                      </p>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700 first-letter:capitalize"
                      >
                        {t('name')}
                      </label>
                      <input
                        type="text"
                        name="name"
                        id="name"
                        autoComplete="name"
                        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        onChange={handleChange}
                        value={data['name'] ?? ''}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-6 -mx-6 -mb-6 px-6 py-4 bg-gray-50 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm  disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:bg-blue-600"
                    disabled={loading || resultCreateFoodUnit.loading}
                  >
                    {t('Add')}
                    {(loading || resultCreateFoodUnit.loading) && (
                      <LoadingIcon className="ml-2 h-5 w-5 text-inherit" />
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={close}
                  >
                    {t('Cancel')}
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
