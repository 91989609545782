import React, {Fragment, useEffect, useState } from 'react';
import classNames from 'classnames'

import { useTranslation } from 'react-i18next'
import { Tsquery } from 'pg-tsquery'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { Menu, Transition } from '@headlessui/react'
import {DotsVerticalIcon } from '@heroicons/react/solid'
import LoadingIcon from '../../atom/LoadingIcon'

import { useMutation } from '@apollo/client'

const BulkActionList = ({group, setLoading, selected, byFilters, allFilters, ...props}) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [setStatus, { loading }] = useMutation(group.mutation)

  useEffect(() => {
    console.log("loading", loading);
    setLoading(loading)
  }, [loading])

  const handleClick = async (groupVariables, refetchQueries, indexKey) => {
    searchParams.append('ts', Date.now())
    const tsquery = new Tsquery()
    var recordsIds = {}
    recordsIds[indexKey] = byFilters ? [] : selected
    var filters = Object.keys(allFilters).reduce(function(obj, key) {
      if (searchParams.get(key)) {
          obj[key] = allFilters[key].type==='text' ? `${tsquery.parse(searchParams.get(key))}`: searchParams.get(key);
      }
      return obj
    }, {});
    
    const variables = { input: { ...recordsIds, filters, ...groupVariables } }
    const { data } = await setStatus({ variables, refetchQueries })
    const rowCount = data[Object.keys(data)[0]]?.integer
    const to = `${pathname}?${searchParams.toString()}`
    const notification = `${rowCount} ${t('rows were updated')}`
    const state = { notification }
    setLoading(loading)
    navigate(to, { state })
  }

  return <Fragment>{group.actions.map(({ name, Icon, variables }, key) => (
    <Menu.Item key={key}>
      {({ active }) => (
        <button
          type="button"
          className={classNames(
            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
            'group w-full flex items-center px-4 py-2 text-sm font-medium whitespace-nowrap'
          )}
          onClick={() => handleClick(
            variables,
            group.refetchQueries,
            group.indexKey
          )}
        >
          <Icon
            className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
            aria-hidden="true"
          />
          {name}
        </button>
      )}
    </Menu.Item>
  ))}</Fragment>
}

const BulkActions = ({
  bulkActions,
  selected,
  byFilters,
  filters,
  ...props
}) => {
  const [loading, setLoading] = useState(false)
  const disabled = selected.length === 0 || loading

  return <Menu as="div" className="relative z-20 inline-block text-left">
    <div>
      <Menu.Button
        className="inline-flex justify-center w-42 rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
        disabled={disabled}
      >
        {loading ? (
          <LoadingIcon className="h-5 w-5" />
        ) : (
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </Menu.Button>
    </div>

    <Transition
      as={Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
        <div className="py-1">
          {Object.keys(bulkActions).map((key, index)=>(
              <BulkActionList
                key={index}
                group={bulkActions[key]}
                setLoading={setLoading}
                selected={selected}
                byFilters={byFilters}
                allFilters={filters}
              />
          ))}
        </div>
      </Menu.Items>
    </Transition>
  </Menu>
}

export default BulkActions;
