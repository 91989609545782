import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function ProductsWithSimilarName({ foodProduct }) {
  const { t } = useTranslation()
  const { data = [] } = useDiarySearch(foodProduct)

  return (
    <section className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <h2 className="text-lg font-medium text-gray-900">
        {t('Similar name')}
      </h2>
      <div className="mt-4 flow-root">
        <ul className="-mb-8 divide-y divide-gray-200">
          {data.map(({ id, name, kcal100G }) => (
            <li key={id} className="py-2 grid grid-cols-6 gap-2 text-sm text-gray-500">
              <Link to={`/foodProducts/${id}`} className="col-start-1 col-span-5 font-medium text-blue-600 hover:text-blue-500 mr-4">
                {name}
              </Link>
              <span className="col-start-6 col-span-1 text-right tabular-nums">
                {kcal100G}
              </span>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6 flex flex-col justify-stretch"></div>
    </section>
  )
}

function useDiarySearch(foodProduct) {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [error, setError] = useState()

  useEffect(() => {
    const q = encodeURI(foodProduct.name.toLowerCase())
    fetch(`${process.env.REACT_APP_DIARY_SEARCH_URL}?q=${q}&size=11&from=0&type=food_product`)
      .then((res) => res.json())
      .then(
        (result) => {
          setLoading(false)
          setData(
            result
              .filter((r) => r.obj_id !== foodProduct.id)
              .map((r) => ({
                id: r.obj_id,
                name: r.name,
                kcal100G: r.kcal_100g,
              }))
              .slice(0,10)
          )
        },
        (error) => {
          setLoading(false)
          setError(error)
        }
      )
  }, [foodProduct])

  return { loading, data, error }
}
