import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import './i18n';

ReactDOM.render(
  <React.StrictMode>
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_APP_IDENTIFIER}
    redirectUri={window.location.origin}
    scope="admin read:current_user user_id email org_id update:current_user_metadata"
    audience={process.env.REACT_APP_API_AUDIENCE ? process.env.REACT_APP_API_AUDIENCE: process.env.REACT_APP_API_URL}

  >
    <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
