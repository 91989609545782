import { gql } from '@apollo/client'

const FOOD_TIPS_REJECT = gql`
	mutation MyMutation($input: BulkFoodTipsRejectInput!) {
		bulkFoodTipsReject(input: $input) {
			integer
		}
	}
`




export default FOOD_TIPS_REJECT;
