import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { gql, useMutation } from '@apollo/client'
import { createContainer } from 'unstated-next'
import SelectField from './SelectField'
import { ChevronDoubleDownIcon } from '@heroicons/react/solid'
import LoadingIcon from '../../../atom/LoadingIcon'

export const SAVE_FOOD_PRODUCT = gql`
  mutation SaveFoodProduct($input: CreateOrUpdateFoodProductInput!) {
    createOrUpdateFoodProduct(input: $input) {
      foodProduct {
        id
      }
    }
  }
`

function useForm(initialState = {}) {
  const [data, setData] = useState({ ...initialState })
  const [typename, setTypename] = useState()
  return { data, setData, typename, setTypename }
}

function getFilteredData(data, allowed) {
  return Object.keys(data)
    .filter(key => allowed.includes(key) || key === 'id')
    .reduce((obj, key) => {
      obj[key] = data[key]
      return obj
    }, {})
}

export const FormContext = createContainer(useForm)

export default function FoodProductForm({ foodProduct, fields }) {
  const { t } = useTranslation()
  const initialState = getFilteredData(foodProduct, fields)
  const [save, { loading }] = useMutation(SAVE_FOOD_PRODUCT)

  return (
    <FormContext.Provider initialState={initialState}>
      <Form fields={fields} onSubmit={save}>
        <div className="px-4 py-6 sm:px-6">
          <div className="grid grid-cols-6 gap-6">
            <FormSubtitle>{t('Food product')}</FormSubtitle>
            <InputField name="name" />
            <InputField name="trashed" type="checkbox" />
            <FieldSeparator full />
            <FormSubtitle>{t('Nutrition per 100 g')}</FormSubtitle>
            <InputField name="kcal100G" type="number" />
            <FieldSeparator />
            <InputField name="fat100G" type="number" />
            <OfWhich />
            <InputField name="saturatedFat100G" type="number" />
            <InputField name="monounsaturatedFat100G" type="number" />
            <InputField name="polyunsaturatedFat100G" type="number" />
            <FieldSeparator />
            <InputField name="carbs100G" type="number" />
            <OfWhich />
            <InputField name="sugar100G" type="number" />
            <FieldSeparator />
            <InputField name="fiber100G" type="number" />
            <InputField name="protein100G" type="number" />
            <InputField name="salt100G" type="number" />
            <FieldSeparator />
            <InputField name="vitaminA100G" type="number" />
            <InputField name="vitaminD100G" type="number" />
            <InputField name="vitaminC100G" type="number" />
            <FieldSeparator />
            <InputField name="calcium100G" type="number" />
            <InputField name="iron100G" type="number" />
            <FieldSeparator />
            <InputField name="fruits100G" type="number" />
            <InputField name="veg100G" type="number" />
            <InputField name="nuts100G" type="number" />
            <InputField name="isHeartFriendly" type="checkbox" />
            <FieldSeparator />
            <InputField name="alcohol100G" type="number" />
            <FieldSeparator full />
            <FormSubtitle>{t('Allergens')}</FormSubtitle>
            <SelectField name="allergens" type="FoodAllergenType" hideLabel />
            <InputField name="missingAllergenInfo" type="checkbox" />
          </div>
        </div>
        <button type="submit" className="block w-full flex justify-center text-sm font-medium text-center px-4 py-4 mt-1 sm:rounded-b-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:bg-blue-600" disabled={loading}>
          {t('Save food product')}
          {loading && <LoadingIcon className="ml-2 h-5 w-5 text-inherit" />}
        </button>
      </Form>
    </FormContext.Provider>
  )
}

function Form({ children, onSubmit }) {
  const form = FormContext.useContainer()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const state = { notification: t('Food product successfully updated') }

  const variables = {
    input: {
      foodProduct: {...form.data}
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { data } = await onSubmit({ variables })
    const { id } = data.createOrUpdateFoodProduct.foodProduct
    navigate(`/foodProducts/${id}?ts=${Date.now()}`, { state })
  }

  return (
    <form method="post" onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

function InputField({ name, type = 'text', size }) {
  const { t } = useTranslation()
  const form = FormContext.useContainer()
  const colSpan = size ? size : type === 'number' ? 2 : 6

  const handleChange = (e) => {
    let value
    switch(e.target.type) {
      case 'checkbox':
        value = !form.data[e.target.name]
        break
      case 'number':
        value = +e.target.value
        break
      default:
        case 'text':
        value = e.target.value
    }
    form.setData({...form.data, [e.target.name]: value})
  }

  if (type === 'checkbox') {
    return (
      <div className="col-span-6 relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={name}
            name={name}
            type="checkbox"
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            checked={form.data[name]}
            onChange={handleChange}
          />
        </div>
        <div className="ml-3 text-sm first-letter:capitalize">
          <label htmlFor={name} className="font-medium text-gray-700">
            {t(name)}
          </label>
        </div>
      </div>
    )
  }

  return (
    <div className={`col-span-6 sm:col-span-6 lg:col-span-${colSpan}`}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 first-letter:capitalize">
        {t(name)}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        autoComplete={name}
        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        onChange={handleChange}
        value={form.data[name] ?? ''}
        required
      />
    </div>
  )
}

function FieldSeparator({ full = false }) {
  const margin = full ? '-mx-6' : ''
  const borderColor = full ? 'border-gray-200' : 'border-gray-100'
  return <div className={`col-span-6 ${margin} border-t ${borderColor}`} />
}

function FormSubtitle({ children }) {
  return (
    <div className="col-span-6 text-lg leading-6 font-medium text-gray-900">
      {children}
    </div>
  )
}

function OfWhich() {
  const { t } = useTranslation()
  return (
    <div className="col-span-6 flex -mb-4 -mt-2 font-normal italic text-sm text-gray-500">
      <ChevronDoubleDownIcon className="h-4 w-4 mt-0.5 mr-1" aria-hidden="true" /> {t('hvorav')}
    </div>
  )
}
