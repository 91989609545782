import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/solid'
import AddItem from './AddItem'

const NewItem = ({
  config,
  handleSubmit,
  ...props
}) => {
  const { t } = useTranslation()
  const [isOpen, setOpen] = useState(false)

  return (
    <>
      <button
        type="button"
        className="inline-flex items-center px-3 py-2.5 ml-3 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() => setOpen(true)}
      >
        <PlusIcon className="w-4 h-4 mr-1" /> {t(config.create_button_text)}
      </button>
      {
        isOpen && <AddItem close={() => setOpen(false)} config={config} handleSubmit={handleSubmit} />
      }
    </>
  )
}

export default NewItem;
