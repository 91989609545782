const addButtonConfig = {
  create_button_text: 'New category',
  initial_state: {
    name: '',
    trashed: false,
    parentId: null
  },
  header: 'Add new category',
  message: 'You will be able to edit category in the next screen.',
  callbackUrl: 'categories'
}

export { addButtonConfig }
