import { useState } from 'react'
import { createContainer } from 'unstated-next'
import useRouterBreadcrumbs from 'use-react-router-breadcrumbs'

function useBreadcrumbsContainer() {
  const [routes, setBreadcrumbs] = useState([])
  const breadcrumbs = useRouterBreadcrumbs(routes)
  return { breadcrumbs, setBreadcrumbs }
}

const BreadcrumbsContainer = createContainer(useBreadcrumbsContainer)

export const BreadcrumbsProvider = BreadcrumbsContainer.Provider

export const useBreadcrumbs = BreadcrumbsContainer.useContainer

export default BreadcrumbsContainer
