import { useState } from 'react'
import { useApolloClient } from '@apollo/react-hooks'
import { gql, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { TrashIcon, SaveIcon } from '@heroicons/react/solid'
import LoadingIcon from '../../../atom/LoadingIcon'

export const GET_UNIT = gql`
  query GetUnit($filter: UnitFilter!) {
    units(filter: $filter, first: 1) {
      nodes {
        id
        unit
        gramsPerUnit
      }
    }
  }
`

const CREATE_UNIT = gql`
  mutation CreateUnit($input: CreateUnitInput!) {
    createUnit(input: $input) {
      unit {
        id
      }
    }
  }
`

export const CREATE_FOOD_UNIT = gql`
  mutation CreateFoodUnit($input: CreateFoodUnitInput!) {
    createFoodUnit(input: $input) {
      foodUnit {
        id
      }
    }
  }
`

const DELETE_FOOD_UNIT = gql`
  mutation DeleteFoodUnit($input: DeleteFoodUnitInput!) {
    deleteFoodUnit(input: $input) {
      foodUnit {
        id
      }
    }
  }
`

export default function FoodProductUnits({ foodProduct }) {
  const { t } = useTranslation()
  const units = foodProduct.foodUnitsByIngredientId?.nodes ?? []
  const initialState = { unit: '', gramsPerUnit: '' }
  const [data, setData] = useState(initialState)
  const client = useApolloClient()
  const [createUnit, resultCreateUnit] = useMutation(CREATE_UNIT)
  const [createFoodUnit, resultCreateFoodUnit] = useMutation(CREATE_FOOD_UNIT)
  const [deleteFoodUnit, resultDelete] = useMutation(DELETE_FOOD_UNIT)
  const refetchQueries = ['GetFoodProduct']

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const save = async (e) => {
    e.preventDefault()
    const getUnitResult = await client.query({
      query: GET_UNIT,
      variables: {
        filter: {
          unit: { equalTo: data.unit },
          gramsPerUnit: { equalTo: +data.gramsPerUnit },
        },
      },
    })
    let unitId = getUnitResult.data?.units?.nodes[0]?.id
    const ingredientId = foodProduct.id
    const timestamp = new Date().toISOString()
    if (!unitId) {
      const result = await createUnit({
        variables: {
          input: {
            unit: {
              unit: data.unit,
              gramsPerUnit: +data.gramsPerUnit,
              createdAt: timestamp,
              trashed: false,
            },
          },
        },
      })
      unitId = result.data.createUnit.unit.id
    }
    await createFoodUnit({ variables: {
      input: {
        foodUnit: { ingredientId, unitId },
      },
    }, refetchQueries})
    setData(initialState)
  }

  const remove = (e) => {
    e.preventDefault()
    const variables = {
      input: {
        id: +e.target.dataset.id
      },
    }
    deleteFoodUnit({ variables, refetchQueries })
  }

  return (
    <section className="bg-white mt-6 px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <div className="grid grid-cols-6 gap-2">
        <h2 className="col-start-1 col-span-4 text-lg font-medium text-gray-900 mt-0.5">
          {t('Units')}
        </h2>
      </div>
      {units.length > 0 && (
        <div className="mt-4 flow-root">
          <ul className="-mb-4 divide-y divide-gray-200">
            {units.map(({ id, unit }) => (
              <li key={id} className="py-2 grid grid-cols-6 gap-2 text-sm text-gray-500">
                <span className="col-start-1 col-span-5 mr-4">
                  <span className="font-medium text-gray-900">{unit.unit}</span>{` `}
                  ({new Intl.NumberFormat('nb-NO', { minimumFractionDigits: 1 }).format(unit.gramsPerUnit)}g)
                </span>
                <span className="col-start-6 col-span-1 text-right">
                  {(unit.unit === 'g' && unit.gramsPerUnit === 1) ? (
                    <button type="button" disabled>
                      <TrashIcon className="h-5 w-5 fill-gray-300 cursor-not-allowed" />
                    </button>
                  ) : (
                    <form method="post" onSubmit={remove} data-id={id}>
                      <button type="submit" disabled={resultDelete.loading} className="disabled:opacity-75 disabled:cursor-not-allowed">
                        {resultDelete.loading ? (
                          <LoadingIcon className="h-5 w-5 text-inherit" />
                        ) : (
                          <TrashIcon className="h-5 w-5 fill-blue-600 hover:fill-blue-700" />
                        )}
                      </button>
                    </form>
                  )}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="-mb-5 -mx-6 mt-6 px-6 py-6 rounded-b-lg flex flex-col justify-stretch bg-gray-50">
        <form method="post" onSubmit={save}>
          <div className="flex rounded-md shadow-sm">
            <div className="flex -space-x-px">
              <div className="w-1/2 flex-1 min-w-0">
                <label htmlFor="unit" className="sr-only">
                  {t('Unit')}
                </label>
                <input
                  type="text"
                  name="unit"
                  id="unit"
                  className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none rounded-l-md focus:z-10 sm:text-sm border-gray-300"
                  placeholder={t('Unit')}
                  onChange={handleChange}
                  value={data['unit']}
                  required
                />
              </div>
              <div className="flex-1 min-w-0">
                <label htmlFor="gramsPerUnit" className="sr-only">
                  Grams per unit
                </label>
                <input
                  type="number"
                  name="gramsPerUnit"
                  id="gramsPerUnit"
                  className="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-none focus:z-10 sm:text-sm border-gray-300"
                  placeholder="Grams"
                  onChange={handleChange}
                  value={data['gramsPerUnit']}
                  required
                />
              </div>
            </div>
            <button
              type="submit"
              className="-ml-px relative inline-flex items-center space-x-2 px-3 py-2 text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:bg-blue-600"
              disabled={resultCreateUnit.loading || resultCreateFoodUnit.loading}
            >
              {resultCreateUnit.loading || resultCreateFoodUnit.loading ? (
                <LoadingIcon className="h-5 w-5 text-inherit" />
              ) : (
                <SaveIcon className="h-5 w-5 fill-white" aria-hidden="true" />
              )}
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}
