import classNames from "classnames";
import React, { useState, useRef, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { useTranslation } from 'react-i18next'
import { numberFormat } from 'utils/numbers'
import { dateTimeFormat } from 'utils/dates'
import { useSelectAll } from 'utils/selection'
import { useCategories } from './hooks/useCategories'
import {addButtonConfig} from './config/addButtonConfig'

import { useMutation } from '@apollo/client'

import { ADD_CATEGORY } from '../../mutation/AddCategory.js'

import PageHeader from '../../atoms/PageHeader'
import SortableColumn from '../../atoms/SortableColumn'
import Pagination from '../../atom/Pagination'
const PAGESIZE = 20


var filters = {
  fullText: { type: 'text' },
  parentId: { type: 'boolean', values: [
    {'name': 'Is parents', id: true},
    {'name': 'Is children', id: false}
  ]},
  trashed: { type: 'boolean', values: [
    { id: 'false', name: 'Published' },
    { id: 'true', name: 'Archived' },
  ]}
}

const bulkActions = {}

// This shold be uncommented and updated for bulk mutations... Remove if not needed
// import REJECT from '../../mutation/FoodTipsReject.js'
// const bulkActions = {
// 	reject: {
// 		type: 'action',
// 		mutation: REJECT,
// 		refetchQueries: [CATEGORIES],
// 		indexKey: 'foodTipsIds',
// 		actions: [
// 			{ name: 'Bulk reject', Icon: XCircleIcon, variables: {} },
// 		]
// 	}
// }

const Categories = ({
	...props
}) => {
	const categoriesClass = classNames("categories-container")
	const { t } = useTranslation()

  const [addCategory, { loading: lbtn }] = useMutation(ADD_CATEGORY)

  const handleSubmit = async (data) => {
    try {
      const refetchQueries = ['CategoryQuery']
      var currentdate = new Date();
      const input = { category:  {...data, ...{'createdAt': dateTimeFormat(currentdate, 'yyyy-MM-dd HH:ii:ss')}} }
      const variables = { input }
      const result = await addCategory({ variables, refetchQueries })
      const { id } = result.data.createCategory.category
      return id
    } catch (e) {
      alert(`Error: ${e.message}`);
      return
    }
  }


	const {
    categories,
		totalCount
	} = useCategories(PAGESIZE)

	const selectAllRef = useRef()
  const { selected, setSelected, selection } = useSelectAll(selectAllRef, categories)


	return <div className={ categoriesClass }>
		<div className="px-6">
			<PageHeader
				filters={filters}
				pagesize={PAGESIZE}
				showBulk={true}
        selected={selected}
				setSelected={setSelected}
        selection={selection}
				bulkActions={bulkActions}
				totalCount={totalCount}
				selectAll={setSelected}
        add_button_config={addButtonConfig}
        showAdd={true}
        handleSubmit={handleSubmit}
			/>

			<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										<SortableColumn field={t("NAME")} defaultOrder={`CREATED_ASC`}>
											<input
                        name="select-all"
                        type="checkbox"
                        className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        value="current-page"
                        checked={
                          selected.length > 0 &&
                          (selected.length >= PAGESIZE ||
                            selected.length === totalCount)
                        }
                        onChange={setSelected} ref={selectAllRef} />
										</SortableColumn>
									</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <SortableColumn field={t("PARENT_ID")} defaultOrder={`PARENT_ID`}/>
                  </th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										<SortableColumn field={t("CREATED_AT")} defaultOrder={`CREATED_AT_ASC`}/>
									</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <SortableColumn field={t("TRASHED")}  defaultOrder={`TRASHED_ASC`}/>
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    <span className="sr-only">Edit</span>
                  </th>
								</tr>
							</thead>
							<tbody>
								  {
										categories && categories.map((items, listIdx) => (
											<tr key={items.id} className={classNames(
												`text-${items?.status?.toLowerCase()}`,
                        { 'bg-indigo-50': selected.includes(items.id) },
												{
													'bg-white': listIdx % 2 === 0,
													'bg-gray-50': listIdx % 2 !== 0
												}
											)}>
												<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
													{selected.includes(items.id) && <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />}
													<input name="foodTips" type="checkbox" className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" value={items.id} checked={selected.includes(items.id)} onChange={setSelected} />
													{items.name}
												</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{items?.parent?.name}</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{dateTimeFormat(items.createdAt, 'dd.MM.yy HH:ii:ss')}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          {items.trashed ? (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              {t('Archived')}
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              {t('Published')}
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                          <Link
                            to={`/categories/${items.id}`}
                            className="font-medium text-indigo-600 hover:text-indigo-900"
                          >
                            Edit
                          </Link>
                        </td>
											</tr>
											))
										}
								</tbody>
						</table>
						<Pagination totalCount={totalCount} pageSize={PAGESIZE} />
					</div>
				</div>
			</div>
		</div>
	</div>
}

export default Categories;
