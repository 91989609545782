import { Fragment } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Listbox, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

export default function FilterSingle({ name, values = [] }) {
  const { t } = useTranslation()
  const [searchParams, setSearchParams] = useSearchParams()
  const paramValue = searchParams.get(name) || 'all'
  const options = [{ id: 'all', name: 'All' }, ...flattenNodes(values)]
  const selected = options.find((option) => String(option.id) === paramValue)

  const handleChange = ({ id }) => {
    id === 'all' ? searchParams.delete(name) : searchParams.set(name, id)
    searchParams.delete('offset')
    setSearchParams(searchParams)
  }

  return (
    <div className="col-span-6 sm:col-span-6 lg:col-span-6">
      <Listbox value={selected} onChange={handleChange}>
        {({ open }) => (
          <>
            <div className="relative">
              <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                <span className="block truncate">
                  <span className="inline-block text-gray-500 first-letter:capitalize">
                    {t(name)}:
                  </span>{' '}
                  <span className="font-medium text-gray-900">
                    {t(selected?.name)}
                  </span>
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>
              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-20 mt-1 w-full min-w-fit bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                  {options.map((option, key) => (
                    <Option
                      key={key}
                      option={option}
                      name={name}
                      selected={selected}
                    />
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}

function Option({ option, name, selected }) {
  const { t } = useTranslation()
  const indent = option.parentId ? 'pl-6' : ''

  return (
    <>
      <Listbox.Option value={option} className={`${indent} max-h-60 hover:bg-indigo-50`}>
        <label className="relative flex items-start py-2 px-3">
          <div className="flex items-center h-5">
            <input
              name={name}
              type="radio"
              className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              value={option.id}
              defaultChecked={selected?.id === option.id}
            />
          </div>
          <div className="ml-2.5 text-sm">
            <span
              className={classNames(
                selected?.id === option.id ? 'font-semibold' : 'font-normal',
                'block truncate text-gray-700'
              )}
            >
              {t(option.name)}
            </span>
          </div>
        </label>
      </Listbox.Option>
      {option.id === 'all' && <div className="my-1 border-b border-gray-200" />}
    </>
  )
}

function flattenNodes(nodes) {
  return nodes.reduce((prev, current) => {
    const { id, name, parentId, children } = current
    prev.push({ id, name, parentId })
    children?.nodes?.forEach((node) => {
      const { id, name, parentId } = node
      prev.push({ id, name, parentId })
    })
    return prev
  }, [])
}
