import { gql } from '@apollo/client'

export const FIELDS = [
  'name',
  'trashed',
  'parentId',
]

export const CATEGORY_DETAILS = gql`
  query CategoryDetailsQuery($id: Int!) {
    category(id: $id) {
      id
      ${FIELDS.join('\n')}
      createdAt
      parent {
        name
        id
      }
    }
  }
`;

// export default CATEGORY_DETAILS;
