import { Route, Routes } from 'react-router-dom'
import CategoriesList from './CategoriesList'
import CategoriesEdit from './CategoriesEdit'

export default function Categories() {
  return (
    <Routes>
      <Route path=":id" element={<CategoriesEdit />} />
      <Route index element={<CategoriesList />} />
    </Routes>
  )
}
