import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectAll } from 'utils/selection'
import classNames from 'classnames'
import FilterText from 'components/atom/FilterText'
import FilterSingle from 'components/atom/FilterSingle'
import SortableColumn from 'components/atom/SortableColumn'
import SelectedRows from 'components/atom/SelectedRows'
import Pagination from 'components/atom/Pagination'
import { useInstructors } from './hooks/useInstructors'
import BulkActions from './partials/BulkActions'

const PAGESIZE = 20

const STATUS = [
  { id: 'active', name: 'active' },
  { id: 'hasQuit', name: 'hasQuit' },
  { id: 'onLeave', name: 'onLeave' },
  { id: 'quitting', name: 'quitting' },
]

export default function InstructorList() {
  const { t } = useTranslation()
  const selectAllRef = useRef()
  const { instructors, totalCount } = useInstructors(PAGESIZE)
  const { selected, setSelected, selection } = useSelectAll(selectAllRef, instructors)

  return (
    <div className="px-6">
      <div className="grid grid-cols-1">
        <div className="row-start-1 flex flex-wrap justify-start mb-3 text-sm">
          <div className="relative mr-3 mb-3">
            <FilterText name="name" placeholder={t('Search instructors')} />
          </div>
          <div className="mr-3 mb-3">
            <FilterSingle name="status" values={STATUS} />
          </div>
        </div>
        <div className="row-start-1 flex justify-end mb-6 text-sm">
          <BulkActions selected={selected} selection={selection} />
        </div>
      </div>
      <SelectedRows
        selected={selected}
        setSelected={setSelected}
        selection={selection}
        totalCount={totalCount}
        pageSize={PAGESIZE}
      />
      <div className="overflow-hidden mb-10 border border-gray-200 sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50 text-left text-xs text-gray-900 uppercase tracking-wider">
                <th className="sticky left-0 z-10 px-4 py-4 bg-inherit font-medium">
                  <SortableColumn field="NAME">
                    <input
                      name="select-all"
                      type="checkbox"
                      className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      value="current-page"
                      checked={
                        selected.length > 0 &&
                        (selected.length >= PAGESIZE ||
                          selected.length === totalCount)
                      }
                      onChange={setSelected}
                      ref={selectAllRef}
                    />
                  </SortableColumn>
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="SLUG" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="PHONE" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="REGION" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="LEVEL" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="SEARCH_BOOST" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="STATUS" />
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {instructors.map((instructor) => (
                <tr
                  key={instructor.id}
                  className={classNames(
                    { 'bg-indigo-50': selected.includes(instructor.id) },
                    'text-sm text-gray-500 whitespace-nowrap bg-white hover:bg-indigo-50 border-t border-gray-100'
                  )}
                >
                  <td className="sticky relative left-0 z-10 max-w-xs sm:max-w-sm px-4 py-3 bg-inherit font-medium text-gray-900 text-left text-ellipsis overflow-hidden">
                    {selected.includes(instructor.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )}
                    <input
                      name="instructor"
                      type="checkbox"
                      className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      value={instructor.id}
                      checked={selected.includes(instructor.id)}
                      onChange={setSelected}
                    />
                    {instructor.name}
                  </td>
                  <td className="px-4 py-3">{instructor.slug}</td>
                  <td className="px-4 py-3">{instructor.phone}</td>
                  <td className="px-4 py-3">{instructor.region}</td>
                  <td className="px-4 py-3 text-right tabular-nums">
                    {instructor.level}
                  </td>
                  <td className="px-4 py-3 text-right tabular-nums">
                    {instructor.searchBoost}
                  </td>
                  <td className="px-4 py-3">
                    {instructor.status === 'active' ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        {t(instructor.status)}
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {t(instructor.status)}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalCount={totalCount} pageSize={PAGESIZE} />
      </div>
    </div>
  )
}
