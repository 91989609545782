import { gql } from '@apollo/client'

const FOOD_TIPS = gql`
query Query(
	$filter: CustomFoodFilter
	$pagesize: Int
	$offset: Int
	$orderBy: [CustomFoodsOrderBy!]
) {
	customFoods(
		filter: $filter
		first: $pagesize
		offset: $offset
		orderBy: $orderBy
	) {
		nodes {
			id
			data
			created
			name
			kcal100G
		}
		pageInfo {
			hasNextPage
			hasPreviousPage
		}
		totalCount
	}
}

`

export default FOOD_TIPS;
