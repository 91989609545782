import { useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { TrashIcon, SaveIcon } from '@heroicons/react/solid'
import LoadingIcon from '../../../atom/LoadingIcon'

const CURRENT_USER_ID  = gql`
  query CurrentUserId {
    currentUserId
  }
`

const CREATE_BARCODE = gql`
  mutation CreateBarcode($input: CreateBarcodeInput!) {
    createBarcode(input: $input) {
      barcode {
        id
      }
    }
  }
`

const DELETE_BARCODE = gql`
  mutation DeleteBarcode($input: DeleteBarcodeInput!) {
    deleteBarcode(input: $input) {
      barcode {
        id
      }
    }
  }
`

export default function FoodProductBarcodes({ foodProduct }) {
  const { t } = useTranslation()
  const barcodes = foodProduct.barcodesByProductId?.nodes ?? []
  const initialState = { barcode: '' }
  const [data, setData] = useState(initialState)
  const { data: auth } = useQuery(CURRENT_USER_ID)
  const [createBarcode, resultCreate] = useMutation(CREATE_BARCODE)
  const [deleteBarcode, resultDelete] = useMutation(DELETE_BARCODE)
  const refetchQueries = ['GetFoodProduct']

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const create = async (e) => {
    e.preventDefault()
    const timestamp = new Date().toISOString()
    const variables = {
      input: {
        barcode: {
          productId: foodProduct.id,
          barcode: data.barcode,
          created: timestamp,
          lastModified: timestamp,
          data: {
            approved: true,
            first_user: auth.currentUserId,
            first_timestamp: timestamp,
          },
        },
      },
    }
    await createBarcode({ variables, refetchQueries })
    setData(initialState)
  }

  const remove = (e) => {
    e.preventDefault()
    const variables = {
      input: {
        id: +e.target.dataset.id
      },
    }
    deleteBarcode({ variables, refetchQueries })
  }

  return (
    <section className="bg-white mt-6 px-4 py-5 shadow sm:rounded-lg sm:px-6">
      <div className="grid grid-cols-6 gap-2">
        <h2 className="col-start-1 col-span-4 text-lg font-medium text-gray-900 mt-0.5">
          {t('Barcodes')}
        </h2>
      </div>
      {barcodes.length > 0 && (
        <div className="mt-4 flow-root">
          <ul className="-mb-3 divide-y divide-gray-200">
            {barcodes.map(({ id, barcode, data }) => (
              <li key={id} className="py-2 grid grid-cols-6 gap-2 text-sm text-gray-500">
                <span className="col-start-1 col-span-5 mr-4">
                  <span className="font-medium text-gray-900 tabular-nums">{barcode}</span>
                  <span className="block text-xs">
                    {data.approved ? (
                      <span className="text-green-500">{t('Approved')}</span>
                    ) : (
                      <span className="text-red-500">{t('Not approved')}</span>
                    )}
                    <span className="text-gray-500 font-medium px-2">·</span>
                    {data.source ?? t('No source')}
                  </span>
                </span>
                <span className="col-start-6 col-span-1 text-right">
                  <form method="post" onSubmit={remove} data-id={id}>
                    <button type="submit" disabled={resultDelete.loading} className="disabled:opacity-75 disabled:cursor-not-allowed">
                      {resultDelete.loading ? (
                        <LoadingIcon className="h-5 w-5 text-inherit" />
                      ) : (
                        <TrashIcon className="h-5 w-5 fill-blue-600 hover:fill-blue-700" />
                      )}
                    </button>
                  </form>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="-mb-5 -mx-6 mt-6 px-6 py-6 rounded-b-lg flex flex-col justify-stretch bg-gray-50">
        <form method="post" onSubmit={create}>
          <div className="flex rounded-md shadow-sm">
            <div className="relative flex items-stretch flex-grow focus-within:z-10">
              <label htmlFor="barcode" className="sr-only">
                {t('Barcode')}
              </label>
              <input
                type="text"
                name="barcode"
                id="barcode"
                className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md sm:text-sm border-gray-300"
                placeholder={t('Barcode')}
                onChange={handleChange}
                value={data['barcode']}
                required
              />
            </div>
            <button
              type="submit"
              className="-ml-px relative inline-flex items-center space-x-2 px-3 py-2 text-sm font-medium rounded-r-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:bg-blue-600"
              disabled={resultCreate.loading}
            >
              {resultCreate.loading ? (
                <LoadingIcon className="h-5 w-5 text-inherit" />
              ) : (
                <SaveIcon className="h-5 w-5 fill-white" aria-hidden="true" />
              )}
            </button>
          </div>
        </form>
      </div>
    </section>
  )
}
