import { useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import CATEGORIES from '../../../query/Categories.js';

function getFilter(searchParams) {
  const filter = {}

  if (searchParams.has('fullText')) {
    filter['fullText'] = { matches: searchParams.get('fullText') }
  }

  if (searchParams.has('parentId')) {
    const parentId =
    filter['parentId'] = {isNull: searchParams.get('parentId') === 'true'};
  }

  if (searchParams.has('trashed')) {
    filter['trashed'] = { equalTo: searchParams.get('trashed') === 'true' }
  }

  return Object.keys(filter).length === 0 ? undefined : filter
}

export function useCategories(pagesize) {
  const [searchParams] = useSearchParams()
  const offset = +searchParams.get('offset') || 0
  const orderBy = searchParams.get('orderBy') || 'CREATED_AT_DESC'
  const filter = getFilter(searchParams)

  const result = useQuery(CATEGORIES, {
    variables: { filter, pagesize, offset, orderBy },
    nextFetchPolicy: 'cache-and-network',
  })

  const categories = result.data?.categories?.nodes ?? []
  const { totalCount = 0 } = result.data?.categories ?? {}

  return { ...result, categories, totalCount }
}
