import { Route, Routes } from 'react-router-dom'
import FoodProductList from './FoodProductList'
import FoodProductEdit from './FoodProductEdit'

export default function FoodProducts() {
  return (
    <Routes>
      <Route path=":id" element={<FoodProductEdit />} />
      <Route index element={<FoodProductList />} />
    </Routes>
  )
}
