import React, { useState } from 'react';

const FormSubtitle = ({
  children,
  ...props
}) => {
  return <div className="col-span-6 text-lg leading-6 font-medium text-gray-900">
    {children}
  </div>
}

export default FormSubtitle;
