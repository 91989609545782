import { useSearchParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

const INSTRUCTORS = gql`
  query GetInstructors(
    $filter: InstructorFilter
    $pagesize: Int
    $offset: Int
    $orderBy: [InstructorsOrderBy!]
  ) {
    instructors(
      filter: $filter
      first: $pagesize
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        id
        name
        slug
        phone
        region
        level
        searchBoost
        status
        user {
          email
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

export function useFilter() {
  const filter = {}
  const [searchParams] = useSearchParams()

  if (searchParams.has('name')) {
    filter['name'] = { likeInsensitive: `%${searchParams.get('name')}%` }
  }

  if (searchParams.has('status')) {
    filter['status'] = { equalTo: searchParams.get('status') }
  }

  return Object.keys(filter).length === 0 ? undefined : filter
}

export function useInstructors(pagesize) {
  const [searchParams] = useSearchParams()
  const offset = +searchParams.get('offset') || 0
  const orderBy = searchParams.get('orderBy') || 'NAME_ASC'
  const filter = useFilter()
  const variables = { filter, pagesize, offset, orderBy }
  const nextFetchPolicy = 'cache-and-network'

  const result = useQuery(INSTRUCTORS, { variables, nextFetchPolicy })
  const instructors = result.data?.instructors?.nodes ?? []
  const { totalCount = 0 } = result.data?.instructors ?? {}

  return { ...result, instructors, totalCount }
}
