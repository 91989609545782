import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery, useMutation } from '@apollo/client'
import { createContainer } from 'unstated-next'
import {getFilteredData, getPatch} from 'utils/filters'

import CATEGORIES from '../../../query/Categories.js';
import { UPDATE_CATEGORY } from '../../../mutation/AddCategory.js'

import InputField from '../../../atoms/InputField'
import FieldSeparator from '../../../atoms/FieldSeparator'
import FormSubtitle from '../../../atoms/FormSubtitle'
import SelectField from '../../../atoms/SelectField'
import LoadingIcon from '../../../atom/LoadingIcon'



function useForm(initialState = {}) {
  const [data, setData] = useState({ ...initialState })
  const [typename, setTypename] = useState()
  return { data, setData, typename, setTypename }
}

export const FormContext = createContainer(useForm)

function Form({ children, onSubmit, fields }) {
  const form = FormContext.useContainer()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const state = { notification: t('Category successfully updated') }

  const variables = {
    input: {
      id: form.data.id,
      patch: getPatch(form.data, fields)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const { data } = await onSubmit({ variables })
    const { id } = data.updateCategory.category;
    console.log("updated...", id);
    navigate(`/categories/${id}?ts=${Date.now()}`, { state })
  }

  return (
    <form method="post" onSubmit={handleSubmit}>
      {children}
    </form>
  )
}

const EditForm = ({
  category,
  fields,
  ...props
}) => {
  const { t } = useTranslation()
  const initialState = getFilteredData(category, fields)

  const [save, { loading }] = useMutation(UPDATE_CATEGORY)

  const result = useQuery(CATEGORIES, {
    variables: { filter: {parentId: {isNull: true}}, orderBy: "CREATED_AT_DESC" },
    nextFetchPolicy: 'cache-and-network',
  })

  const selectValues = result?.data?.categories?.nodes ?? []

  return (
    <FormContext.Provider initialState={initialState}>
      <Form fields={fields} onSubmit={save} fields={fields}>
        <div className="px-4 py-6 sm:px-6">
          <div className="grid grid-cols-6 gap-6">
            <FormSubtitle>{t('Category')}</FormSubtitle>
            <InputContainer name="name" />
            <InputContainer name="trashed" type="checkbox" />
            <FieldSeparator full />
            <FormSubtitle>{t('Parent')}</FormSubtitle>
            <SelectedContainer selectValues={selectValues}/>
          </div>
        </div>
        <button type="submit" className="block w-full flex justify-center text-sm font-medium text-center px-4 py-4 mt-1 sm:rounded-b-lg text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500 disabled:opacity-75 disabled:cursor-not-allowed disabled:hover:bg-blue-600" disabled={loading}>
          {t('Save category')}
          {loading && <LoadingIcon className="ml-2 h-5 w-5 text-inherit" />}
        </button>
      </Form>
    </FormContext.Provider>
  )
}

function InputContainer({name, type = 'text', size}) {
  const form = FormContext.useContainer()
  return <InputField name={name} type={type} size={size} form={form}/>
}

function SelectedContainer({selectValues}) {
  const form = FormContext.useContainer()

  const values = selectValues.map((value) => ({ id: value.id, name: value.name }));

  return <SelectField
    name="parentId"
    hideLabel
    form={form}
    values={values}
  />
}

export default EditForm
