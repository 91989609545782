import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { format } from 'date-fns'
import { nb } from 'date-fns/locale'


import Loading from '../../layout/Loading'
import DeleteModel from '../../atoms/DeleteModel'
import { useBreadcrumbs } from '../../container/BreadcrumbsContainer'

import EditForm from './partials/EditForm.js'



import {FIELDS, CATEGORY_DETAILS} from '../../query/CategoryDetails.js'
import { DELETE_CATEGORY } from '../../mutation/DeleteCategory.js'

const config = {
  notification: 'Category successfully deleted',
  callbackUrl: '/categories',
  title: 'Delete category',
  message: 'Are you sure you want to delete the category'
}

export default function CategoriesEdit () {
  const { t } = useTranslation()
  const params = useParams()
  const [isOpen, setIsOpen] = useState(false)
  const { setBreadcrumbs } = useBreadcrumbs()
  const variables = { id: Number(params.id) }
  const { loading, data } = useQuery(CATEGORY_DETAILS, { variables })
  const { category: obj } = data ?? {}
  const name = obj?.name ?? ' '

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  useEffect(() => {
    setBreadcrumbs([
      { path: '/categories/:id', breadcrumb: name },
    ])
    return function cleanup() {
      setBreadcrumbs([
        { path: '/categories/:id', breadcrumb: ' ' },
      ])
    }
  }, [name, setBreadcrumbs, t])

  if (loading) return <Loading />

  return (<>
    {/* info header */}
    <div className="mt-6 max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-6">
      <div className="flex items-center space-x-5">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{obj.name}</h1>
          <p className="text-sm font-medium text-gray-500">
            {t('Created at')} <time dateTime={obj.createdAt} className="text-gray-900">{format(new Date(Date.parse(obj.createdAt)), 'MMMM d, yyyy HH:ii:ss', { locale: nb })}</time>
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
        <button
          type="button"
          className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          onClick={open}
        >
          {t('Delete category')} ...
        </button>
        {
          isOpen && (
            <DeleteModel obj={obj} close={close} config={config} mutationQuery={DELETE_CATEGORY}  refetchQueriesStr={'CategoryQuery'} />
          )
        }
      </div>
    </div>

    <div className="mt-6 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3 pb-16">
      <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        <section aria-labelledby="food-product-title">
          <div className="bg-white shadow sm:rounded-lg">
            <EditForm category={obj} fields={FIELDS}/>
          </div>
        </section>
      </div>
    </div>
  </>)


}
