import { Route, Routes } from 'react-router-dom'
import RecipeList from './RecipeList'

export default function Recipes() {
  return (
    <Routes>
      <Route index element={<RecipeList />} />
    </Routes>
  )
}
