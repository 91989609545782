import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { HeartIcon } from '@heroicons/react/solid'
import { HeartIcon as HeartEmpty } from '@heroicons/react/outline'
import { numberFormat } from 'utils/numbers'
import { dateTimeFormat } from 'utils/dates'
import { useSelectAll } from 'utils/selection'
import classNames from 'classnames'
import FilterText from 'components/atom/FilterText'
import FilterSingle from 'components/atom/FilterSingle'
import SortableColumn from 'components/atom/SortableColumn'
import SelectedRows from 'components/atom/SelectedRows'
import Pagination from 'components/atom/Pagination'
import { useFilters, useRecipes } from './hooks/useRecipes'
import BulkActions from './partials/BulkActions'

const PAGESIZE = 20

const PUBLISHED = [
  { id: 'true', name: 'Published' },
  { id: 'false', name: 'Draft' },
]

const IS_HEART_FRIENDLY = [
  { id: 'true', name: 'Yes' },
  { id: 'false', name: 'No' },
]

export default function RecipeList() {
  const { t } = useTranslation()
  const { data, recipes, totalCount } = useRecipes(PAGESIZE)
  const { categories, tags } = useFilters()
  const selectAllRef = useRef()
  const { selected, setSelected, selection } = useSelectAll(selectAllRef, recipes)

  return (
    <div className="px-6">
      <div className="grid grid-cols-1">
        <div className="row-start-1 flex flex-wrap justify-start mb-3 text-sm">
          <div className="relative mr-3 mb-3">
            <FilterText name="name" placeholder={t('Search recipes')} />
          </div>
          <div className="mr-3 mb-3">
            <FilterSingle name="published" values={PUBLISHED} />
          </div>
          <div className="mr-3 mb-3">
            <FilterSingle name="isHeartFriendly" values={IS_HEART_FRIENDLY} />
          </div>
          <div className="mr-3 mb-3">
            <FilterSingle name="recipeCategoriesByRecipeId" values={categories} />
          </div>
          <div className="mr-3 mb-3">
            <FilterSingle name="recipeTagsByRecipeId" values={tags} />
          </div>
        </div>
        <div className="row-start-1 flex justify-end mb-6 text-sm">
          <BulkActions selected={selected} selection={selection} data={data} />
        </div>
      </div>
      <SelectedRows
        selected={selected}
        setSelected={setSelected}
        selection={selection}
        totalCount={totalCount}
        pageSize={PAGESIZE}
      />
      <div className="overflow-hidden mb-10 border border-gray-200 sm:rounded-lg">
        <div className="overflow-x-auto">
          <table className="min-w-full">
            <thead>
              <tr className="bg-gray-50 text-left text-xs text-gray-900 uppercase tracking-wider">
                <th className="sticky left-0 z-10 px-4 py-4 bg-inherit font-medium">
                  <SortableColumn field="NAME">
                    <input
                      name="select-all"
                      type="checkbox"
                      className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      value="current-page"
                      checked={
                        selected.length > 0 &&
                        (selected.length >= PAGESIZE ||
                          selected.length === totalCount)
                      }
                      onChange={setSelected}
                      ref={selectAllRef}
                    />
                  </SortableColumn>
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="KCAL_100G" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="NUM_PORTIONS" />
                </th>
                <th className="px-4 py-4 font-medium">Categories</th>
                <th className="px-4 py-4 font-medium">Tags</th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="IS_HEART_FRIENDLY" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="CREATED_AT" />
                </th>
                <th className="px-4 py-4 font-medium">
                  <SortableColumn field="PUBLISHED" />
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {recipes.map((recipe) => (
                <tr
                  key={recipe.id}
                  className={classNames(
                    { 'bg-indigo-50': selected.includes(recipe.id) },
                    'text-sm text-gray-500 whitespace-nowrap bg-white hover:bg-indigo-50 border-t border-gray-100'
                  )}
                >
                  <td className="sticky relative left-0 z-10 max-w-xs sm:max-w-sm px-4 py-3 bg-inherit font-medium text-gray-900 text-left text-ellipsis overflow-hidden">
                    {selected.includes(recipe.id) && (
                      <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                    )}
                    <input
                      name="recipe"
                      type="checkbox"
                      className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                      value={recipe.id}
                      checked={selected.includes(recipe.id)}
                      onChange={setSelected}
                    />
                    {recipe.name}
                  </td>
                  <td className="px-4 py-3 text-right tabular-nums">
                    {recipe.kcal100G}
                  </td>
                  <td className="px-4 py-3 text-right tabular-nums">
                    {numberFormat(recipe.numPortions, 1)}
                  </td>
                  <td className="px-4 py-3">
                    {recipe.recipeCategoriesByRecipeId.nodes
                      .map((node) => node.category.name)
                      .join(', ')}
                  </td>
                  <td className="px-4 py-3">
                    {recipe.recipeTagsByRecipeId.nodes
                      .map((node) => node.tag.name)
                      .join(', ')}
                  </td>
                  <td className="px-4 py-3">
                    {recipe.isHeartFriendly ? (
                      <HeartIcon className="h-5 w-5 mx-auto text-red-700" />
                    ) : (
                      <HeartEmpty className="h-5 w-5 mx-auto text-gray-500" />
                    )}
                  </td>
                  <td className="px-4 py-3 tabular-nums">
                    {dateTimeFormat(recipe.createdAt)}
                  </td>
                  <td className="px-4 py-3">
                    {recipe.published ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                        {t('Published')}
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                        {t('Draft')}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Pagination totalCount={totalCount} pageSize={PAGESIZE} />
      </div>
    </div>
  )
}
