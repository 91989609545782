import React, { Fragment } from 'react';

import FilterText from '../../atom/FilterText'
import FilterSingle from '../../atom/FilterSingle'
import BulkActions from '../../atoms/BulkActions'
import NewItem from '../NewItem'
import SelectedRows from 'components/atom/SelectedRows'

const PageHeader = ({
  pagesize,
  filters,
  bulkActions,
  totalCount,
  selectAll,
  add_button_config,
  handleSubmit,
  selected,
  setSelected,
  selection,
  showAdd=false,
  ...props
}) => {
  return <Fragment>
    <div className="grid grid-cols-1">
      <div className="row-start-1 flex justify-start mb-6 text-sm">
        <div className="relative text-gray-800 focus-within:text-gray-800">
          <FilterText name="fullText" />
        </div>
        {Object.keys(filters).map((key, index) => {
          return filters[key].type === 'boolean' && <div className="ml-3" key={index}>
            <FilterSingle name={key} values={filters[key].values} />
          </div>
        })}
      </div>
      <div className="row-start-1 flex justify-end mb-6 text-sm">
        {Object.keys(bulkActions).length > 0 && <BulkActions filters={filters} bulkActions={bulkActions} selected={selected} />}
        {showAdd && <NewItem config={add_button_config} handleSubmit={handleSubmit}/>}
      </div>
    </div>
    <SelectedRows
      selected={selected}
      setSelected={setSelected}
      selection={selection}
      totalCount={totalCount}
      pageSize={pagesize}
    />
  </Fragment>

}

export default PageHeader;
