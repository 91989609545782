import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useSelectAll(ref, rows) {
  const { search } = useLocation()
  const [selected, _setSelected] = useState([])
  const [selection, setSelection] = useState('current-page')
  const items = rows.map((row) => row.id)

  useEffect(() => {
    _setSelected([])
  }, [search])

  const setSelected = (e) => {
    let values
    const { value, checked, tagName } = e.target

    if (tagName === 'BUTTON') {
      setSelection(value)
    } else {
      if (value === 'current-page') {
        values = checked ? items : []
      } else {
        values = checked
          ? [...selected, +value]
          : selected.filter((item) => +value !== item)
      }
      _setSelected(values)
    }
  }

  if (ref?.current) {
    ref.current.indeterminate =
      selected.length > 0 && selected.length < items.length
  }

  return { selected, setSelected, selection }
}
