import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'

const InputField = ({
  name,
  size,
  form,
  type='text',
  ...props
}) => {
  const { t } = useTranslation()
  const colSpan = size ? size : type === 'number' ? 2 : 6

  const handleChange = (e) => {
    let value
    switch(e.target.type) {
      case 'checkbox':
        value = !form.data[e.target.name]
        break
      case 'number':
        value = +e.target.value
        break
      default:
        case 'text':
        value = e.target.value
    }
    form.setData({...form.data, [e.target.name]: value})
  }

  if (type === 'checkbox') {
    return (
      <div className="col-span-6 relative flex items-start">
        <div className="flex items-center h-5">
          <input
            id={name}
            name={name}
            type="checkbox"
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
            checked={form.data[name]}
            onChange={handleChange}
          />
        </div>
        <div className="ml-3 text-sm first-letter:capitalize">
          <label htmlFor={name} className="font-medium text-gray-700">
            {t(name)}
          </label>
        </div>
      </div>
    )
  }

  return (
    <div className={`col-span-6 sm:col-span-6 lg:col-span-${colSpan}`}>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 first-letter:capitalize">
        {t(name)}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        autoComplete={name}
        className="mt-1 focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        onChange={handleChange}
        value={form.data[name] ?? ''}
        required
      />
    </div>
  )
}

export default InputField;
