import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloProvider } from '@apollo/client/react'
import { BreadcrumbsProvider } from './components/container/BreadcrumbsContainer'
import Notification from './components/atom/Notification'
import Header from './components/layout/Header.js'
import Sidebar from './components/layout/Sidebar.js'
import Loading from './components/layout/Loading'
import Breadcrumbs from './components/atom/Breadcrumbs'
import AppRoutes from './AppRoutes'

const App = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const {
    loginWithRedirect,
    user,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0()

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    let token
    try {
      token = await getAccessTokenSilently()
    } catch (error) {
      console.log(error)
    }
    if (!token) return headers
    const authorization = token ? `Bearer ${token}` : ''
    return {
      headers: { ...headers, authorization },
    }
  })

  const httpLink = createHttpLink({ uri: process.env.REACT_APP_API_URL })

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: 'include',
    cache: new InMemoryCache(),
    connectToDevTools: true,
  })

  if (isLoading) return <Loading />

  if (!isAuthenticated) loginWithRedirect()

  return (
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className="lg:pl-64 flex flex-col flex-1">
          <header className="App-header">
            <Header
              user={user}
              sidebarOpen={sidebarOpen}
              setSidebarOpen={setSidebarOpen}
            />
          </header>
          <main className="lg:mt-0">
            <BreadcrumbsProvider>
              <Breadcrumbs />
              <AppRoutes />
              <Notification />
            </BreadcrumbsProvider>
          </main>
        </div>
      </BrowserRouter>
    </ApolloProvider>
  )
}

export default App
