import { Route, Routes as Router } from 'react-router-dom'
import Dashboard from './components/pages/Dashboard'
import FoodProducts from './components/pages/FoodProducts'
import FoodTips from './components/pages/FoodTips'
import Recipes from './components/pages/Recipes'
import Categories from './components/pages/Categories'
import Instructors from './components/pages/Instructors'

export default function AppRoutes() {
  return (
    <Router>
      <Route path="/">
        <Route index element={<Dashboard />} />
        <Route path="foodProducts/*" element={<FoodProducts />} />
        <Route path="foodTips">
          <Route index element={<FoodTips />} />
        </Route>
        <Route path="Recipes/*" element={<Recipes />} />
        <Route path="categories/*" element={<Categories />} />
        <Route path="Instructors/*" element={<Instructors />} />
      </Route>
    </Router>
  )
}
