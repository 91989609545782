import { gql } from '@apollo/client'

const CATEGORIES = gql`
	query CategoryQuery($filter: CategoryFilter, $pagesize: Int, $offset: Int, $orderBy: [CategoriesOrderBy!]) {
		categories(filter: $filter, first: $pagesize, offset: $offset, orderBy: $orderBy) {
				nodes {
					name
					id
					createdAt
					trashed
					parent {
						id
						name
						createdAt
						trashed
					}
				}
				pageInfo {
					hasNextPage
					hasPreviousPage
				}
				totalCount
		}
	}
`

export default CATEGORIES;
