import { gql } from '@apollo/client'

export const ADD_CATEGORY = gql`
  mutation AddCategoryMutation($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      category {
        id
      }
    }
  }
`

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($input: UpdateCategoryInput!) {
    updateCategory(input: $input) {
      category {
        id
      }
    }
  }
`
