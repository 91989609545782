export function getFilteredData(data, allowed) {
  return Object.keys(data)
    .filter(key => allowed.includes(key) || key === 'id')
    .reduce((obj, key) => {
      obj[key] = data[key]
      return obj
    }, {})
}

export function getPatch(data, allowed) {
  return Object.keys(data)
    .filter(key => allowed.includes(key))
    .reduce((obj, key) => {
      obj[key] = data[key] !== undefined ? data[key] : null
      return obj
    }, {})
}
