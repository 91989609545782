
function parseFilters(filters, searchParams) {
  let filter = {}

  Object.keys(filters).forEach((key) => {
    if (searchParams.has(key) && filters[key].type === 'text') {
      filter[key] = { matches: searchParams.get(key) }
    } else if (searchParams.has(key) && filters[key].type === 'boolean') {
      const booleanMap = { true: true, false: false }
      filter[key] = { equalTo: booleanMap[searchParams.get(key)] }
    }
  })

  return Object.keys(filter).length === 0 ? undefined : filter
}


function getResults(searchParams, filters, defaultOrder, pagesize) {
  const offset = +searchParams.get('offset') || 0
  const orderBy = searchParams.get('orderBy') || defaultOrder
  const filter = parseFilters(filters, searchParams)

  return {filter, orderBy, offset, pagesize: pagesize}
}



export { getResults, }
