import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchIcon } from '@heroicons/react/solid'
import debounce from 'utils/debounce'

export default function FilterText({ name, placeholder }) {
  const [textParam, setTextParam] = useTextParam(name)

  return (
    <>
      <div className="absolute inset-y-0 left-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5" aria-hidden="true" />
      </div>
      <input
        type="text"
        name={name}
        id={name}
        autoComplete={name}
        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 shadow-sm sm:text-sm border-gray-300 rounded-md placeholder:text-gray-400"
        placeholder={placeholder}
        onChange={setTextParam}
        value={textParam}
        required
      />
    </>
  )
}

function useTextParam(name) {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialState = searchParams.get(name) ?? ''
  const [textParam, _setTextParam] = useState(initialState)

  useEffect(() => {
    if (initialState === '') {
      _setTextParam('')
    }
  }, [initialState])

  const setTextParam = (e) => {
    _setTextParam(e.target.value)
    debounced(e.target.value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounced = useCallback(
    debounce((value) => {
      value === ''
        ? searchParams.delete(name)
        : searchParams.set(name, value)
      searchParams.delete('offset')
      setSearchParams(searchParams)
    }, 500),
    [searchParams]
  )

  return [textParam, setTextParam]
}
