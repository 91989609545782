import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  nb: {
    translation: {
      'Food product': 'Matvare',
      'New food product': 'Ny matvare',
      'Created at': 'Opprettet kl',
      'Delete food product': 'Slett matvare',
      'Delete': 'Slett',
      'Cancel': 'Avbryt',
      'Are you sure you want to delete the food product?': 'Er du sikker på at du vil slette matproduktet? Dataene vil bli permanent fjernet. Denne handlingen kan ikke angres.',
      'Nutrition per 100 g': 'Næringsinnhold Per 100 g',
      'Allergens': 'Allergener',
      'Save food product': 'Lagre matvare',
      'Similar name': 'Lignende navn',
      'Units': 'Enheter',
      'Unit': 'Enhet',
      'Barcodes': 'Strekkoder',
      'Barcode': 'Strekkode',
      'Approved': 'Godkjent',
      'Not approved': 'Ikke godkjent',
      'No source': 'Uten kilde',
      'of which': 'hvorav',
      'name': 'navn',
      'createdAt': 'opprettet kl',
      'trashed': 'status',
      'kcal100G': 'energi',
      'fat100G': 'fett',
      'saturatedFat100G': 'mettede fettsyrer',
      'monounsaturatedFat100G': 'enumettede fettsyrer',
      'polyunsaturatedFat100G': 'flerumettede fettsyrer',
      'carbs100G': 'karbohydrater',
      'sugar100G': 'sukkerarter',
      'fiber100G': 'kostfiber',
      'protein100G': 'protein',
      'salt100G': 'salt',
      'vitaminA100G': 'vitamin A',
      'vitaminD100G': 'vitamin D',
      'vitaminC100G': 'vitamin C',
      'calcium100G': 'kalsium',
      'iron100G': 'jern',
      'fruits100G': 'frukt',
      'veg100G': 'grønnsaker',
      'nuts100G': 'nøtter',
      'isHeartFriendly': 'hjertevennlig',
      'alcohol100G': 'alkohol',
      'allergens': 'allergener',
      'missingAllergenInfo': 'manglende allergeninformasjon',
      'CELERY': 'Selleri',
      'EGG': 'Egg',
      'FISH': 'Fisk',
      'GLUTEN': 'Gluten',
      'LUPINE': 'Lupin',
      'MILK': 'Melk',
      'MOLLUSC': 'Bløtdyr',
      'MUSTARD': 'Sennep',
      'NUT': 'Nøtter',
      'PEANUT': 'Peanøtter',
      'SESAME': 'Sesam',
      'SHELL_FISH': 'Skalldyr',
      'SOY': 'Soya',
      'SULPHUR_DIOXIDE_AND_SULPHITES': 'Svoveldioksid og sulfitter',
      '- Select -': '- Velg -',
      'Food product successfully deleted': 'Matprodukt ble slettet',
      'Food product successfully updated': 'Matvare ble oppdatert',
      'Add new food product': 'Legge til nye matvarer',
      'Add': 'Legg til',
      'You will be able to edit the food product in the next screen.': 'Du vil kunne redigere matvarer i neste skjermbilde.',
      'All': 'Alle',
      'Yes': 'Ja',
      'No': 'Nei',
      'Archived': 'Arkivert',
      'published': 'Status',
      'Published': 'Publisert',
      'Search food products': 'Søk matvarer',
      'Search recipes': 'Søk oppskrifter',
      'recipeCategoriesByRecipeId': 'Kategori',
      'recipeTagsByRecipeId': 'Tag',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: process.env.REACT_APP_LANG,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  })

export default i18n
