import { useQuery, gql } from '@apollo/client';

const enumQuery = gql`
  query  GetEnum($enumName: String!){
    __type(name: $enumName) {
      enumValues {
        name
      }
    }
  }
`


export default function useEnum(enumName) {
    
    const {loading, data} = useQuery(enumQuery, {variables: {enumName: enumName}})


    return {loading: loading, values: data?.__type?.enumValues ? data?.__type?.enumValues.map((row) => row.name) : []}
  }
  