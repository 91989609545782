import { format } from 'date-fns'
import { nb } from 'date-fns/locale'

function numFormat(value, digits = 0) {
  return new Intl.NumberFormat('nb-NO', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value)
}

function dateFormat(date, scheme) {
  return format(new Date(Date.parse(date)), scheme, { locale: nb })
}

export {
  numFormat,
  dateFormat
}
