import { gql } from '@apollo/client'

const FOOD_TIPS_APPROVE = gql`
	mutation FoodTipsApprove($input: BulkFoodTipsApproveInput!) {
		bulkFoodTipsApprove(input: $input) {
			integer
		}
	}
`


export default FOOD_TIPS_APPROVE;
