import classNames from "classnames"

import React, { useState, useRef, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { useSearchParams, useLocation } from 'react-router-dom'

import { useQuery, } from '@apollo/react-hooks'

import FOOD_TIPS from '../../query/FoodTips.js';
import FOOD_TIPS_REJECT from '../../mutation/FoodTipsReject.js'
import FOOD_TIPS_APPROVE from '../../mutation/FoodTipsApprove.js'

import FoodTipsView from '../../atoms/FoodTipsView'

import PageHeader from '../../atoms/PageHeader'
import SortableColumn from '../../atoms/SortableColumn'
import Pagination from '../../atom/Pagination'
import { getResults } from '../../../utils/query'

import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/solid'

import {
	numFormat,
	dateFormat
} from '../../../utils/formatter'

const PAGESIZE = 20

const filters = {
  fullText: { type: 'text' },
}

const bulkActions = {
	approve: {
		type: 'action',
		mutation: FOOD_TIPS_APPROVE,
		refetchQueries: [FOOD_TIPS],
		indexKey: 'foodTipsIds',
		actions: [
			{ name: 'Bulk approve', Icon:  CheckCircleIcon, variables: {} },
		]
	},
	reject: {
		type: 'action',
		mutation: FOOD_TIPS_REJECT,
		refetchQueries: [FOOD_TIPS],
		indexKey: 'foodTipsIds',
		actions: [
			{ name: 'Bulk reject', Icon: XCircleIcon, variables: {} },
		]
	}
}

function useFoodTips() {
	const [searchParams] = useSearchParams()
	const {filter, orderBy, offset, pagesize} = getResults(searchParams, filters, `CREATED_ASC`, PAGESIZE)

	const result = useQuery(FOOD_TIPS, {
    variables: {filter: {...filter, ...{data: {containsKey: "adGrRecommendation"}}}, orderBy, offset, pagesize},
    nextFetchPolicy: 'cache-and-network',
  })

	const pageItems = result.data?.customFoods?.nodes ?? []
  const { totalCount = 0 } = result.data?.customFoods ?? {}
	const pageInfo = result.data?.customFoods?.pageInfo

  return { ...result, pageItems, totalCount, pageInfo }
}

function useSelectAll(items, ref) {
  const { search } = useLocation()
  const [selected, _setSelected] = useState([])

  useEffect(() => {
    _setSelected([])
  }, [search])

  const setSelected = (e) => {
    let values
    const { value, checked } = e.target
    if (value === 'all') {
      values = checked ? items : []
    } else {
      values = checked
        ? [...selected, +value]
        : selected.filter((item) => +value !== item)
    }
    _setSelected(values)
  }

  const selectAll =
    selected.length === 0 ? false : items.length === selected.length

  if (ref?.current) {
    ref.current.indeterminate =
      selected.length > 0 && selected.length < items.length
  }

  return { selectAll, selected, setSelected }
}

function useToggleTotal(pageSize, totalCount) {
  const [totalSelected, setTotalSelected] = useState(pageSize)

  const toggleTotal = () => {
    setTotalSelected(totalSelected === pageSize ? totalCount : pageSize)
  }

  return { totalSelected, toggleTotal }
}

const FoodTips = ({
	...props
}) => {

	const foodTipsClass = classNames("food-tips-container")
	const { t } = useTranslation()

	const {
		pageItems,
		totalCount
	} = useFoodTips()

	const selectAllRef = useRef()
	const items = pageItems.map(({ id }) => id)
	const { selectAll, selected, setSelected } = useSelectAll(items, selectAllRef)
  const { totalSelected, toggleTotal } = useToggleTotal(PAGESIZE, totalCount)



	const [item, setOpen] = useState(false);

  return <div className={ foodTipsClass }>
		<div className="px-6">
			<FoodTipsView
				item={item}
				setOpen={setOpen}

			/>
			<PageHeader
				filters={filters}
				pagesize={PAGESIZE}
				showBulk={true}
				selected={selected}
				totalSelected={totalSelected}
				toggleTotal={toggleTotal}
				bulkActions={bulkActions}
				totalCount={totalCount}
				selectAll={selectAll}
			/>

			<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
					<div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
						<table className="min-w-full divide-y divide-gray-200">
							<thead className="bg-gray-50">
								<tr>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										<SortableColumn field={t("NAME")} defaultOrder={`CREATED_ASC`}>
											<input name="select-all" type="checkbox" className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" value="all" checked={selectAll} onChange={setSelected} ref={selectAllRef} />
										</SortableColumn>
									</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("KCal")}</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("Allergener")}</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">{t("Barcode")}</th>
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										<SortableColumn field={t("CREATED")} defaultOrder={`CREATED_ASC`}/>
									</th>
									<th scope="col" className="relative px-6 py-3">
	                  <span className="sr-only">{t("View")}</span>
	                </th>
								</tr>
							</thead>
							<tbody>
								  {
										pageItems && pageItems.map((items, listIdx) => (
											<tr key={items.id} className={classNames(
												`text-${items?.status?.toLowerCase()}`,
												{
													'bg-white': listIdx % 2 === 0,
													'bg-gray-50': listIdx % 2 !== 0
												}
											)}>
												<td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
													{selected.includes(items.id) && <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />}
													<input name="foodTips" type="checkbox" className="mr-4 focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" value={items.id} checked={selected.includes(items.id)} onChange={setSelected} />
													{items.name}
												</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{numFormat(items.kcal100G, 1)}</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{items.data['allergens']}</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{items.data['barcode']}</td>
												<td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{dateFormat(items.created, 'dd.MM.yy HH:ii:ss')}</td>
												<td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
													<button
														type="button"
														className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
														onClick={()=>setOpen(items)}
													>
														{t("View")}
													</button>
			                  </td>
											</tr>
										))
									}
							</tbody>
						</table>
						<Pagination totalCount={totalCount} pageSize={PAGESIZE} />
					</div>
				</div>
			</div>

		</div>
  </div>
}

export default FoodTips;
