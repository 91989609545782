import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { gql, useLazyQuery } from '@apollo/client'
import { useFilter } from '../hooks/useInstructors'
import { useNotification } from 'utils/notify'
import { Menu, Transition } from '@headlessui/react'
import { ClipboardCopyIcon, DotsVerticalIcon } from '@heroicons/react/solid'
import LoadingIcon from 'components/atom/LoadingIcon'
import classNames from 'classnames'

const INSTRUCTORS = gql`
  query GetInstructors($filter: InstructorFilter) {
    instructors(filter: $filter) {
      nodes {
        id
        user {
          email
        }
      }
    }
  }
`

export default function BulkActions({ selected, selection }) {
  const { t } = useTranslation()
  const { showNotification } = useNotification()
  const filter = useFilter()
  const [getInstructors, { loading }] = useLazyQuery(INSTRUCTORS)

  const disabled = selected.length === 0 || loading

  const actions = [
    {
      name: t('Copy email'),
      Icon: ClipboardCopyIcon,
      field: 'email',
    },
  ]

  async function handleClick(field) {
    if (['email'].includes(field)) {
      const variables =
        selection === 'all' ? { filter } : { filter: { id: { in: selected } } }
      const { data } = await getInstructors({ variables })
      const emails = data?.instructors?.nodes?.map((node) => node?.user?.email)

      navigator.clipboard.writeText(emails.join(',')).then(
        function () {
          const message = `${emails.length} ${t('emails were copied to clipboard')}`
          showNotification(message)
        },
        function (e) {
          console.error('Error when trying to write to the clipboard.', e)
        }
      )
    }
  }

  return (
    <>
      <Menu as="div" className="relative z-20 inline-block text-left">
        <div>
          <Menu.Button
            className="inline-flex justify-center w-42 rounded-md border border-gray-300 shadow-sm px-3 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none"
            disabled={disabled}
          >
            {loading ? (
              <LoadingIcon className="h-5 w-5" />
            ) : (
              <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
            <div className="py-1">
              {actions.map(({ name, Icon, field, value }, key) => (
                <Menu.Item key={key}>
                  {({ active }) => (
                    <button
                      type="button"
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'group w-full flex items-center px-4 py-2 text-sm font-medium whitespace-nowrap'
                      )}
                      onClick={() => handleClick(field, value)}
                    >
                      <Icon
                        className="mr-2 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                      {name}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  )
}
