import { useSearchParams } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'

export function useFilters() {
  const { data } = useQuery(FILTERS)
  const categories = data?.categories?.nodes ?? []
  const tags = data?.tags?.nodes ?? []

  return { categories, tags }
}

export function useRecipes(pagesize) {
  const [searchParams] = useSearchParams()
  const offset = +searchParams.get('offset') || 0
  const orderBy = searchParams.get('orderBy') || 'CREATED_AT_DESC'
  const filter = getFilter(searchParams)

  const result = useQuery(RECIPES, {
    variables: { filter, pagesize, offset, orderBy },
    nextFetchPolicy: 'cache-and-network',
  })

  const recipes = result.data?.recipes?.nodes ?? []
  const { totalCount = 0 } = result.data?.recipes ?? {}

  return { ...result, recipes, totalCount }
}

const FILTERS = gql`
  query GetFilters {
    categories(filter: { parentId: { isNull: true } }) {
      nodes {
        id
        name
        parentId
        children: childCategories {
          nodes {
            id
            name
            parentId
          }
        }
      }
    }
    tags {
      nodes {
        id
        name
      }
    }
  }
`

const RECIPES = gql`
  query GetRecipes(
    $filter: RecipeFilter
    $pagesize: Int
    $offset: Int
    $orderBy: [RecipesOrderBy!]
  ) {
    recipes(
      filter: $filter
      first: $pagesize
      offset: $offset
      orderBy: $orderBy
    ) {
      nodes {
        id
        name
        kcal100G
        numPortions
        cookingTime
        dificulty
        isHeartFriendly
        createdAt
        published
        recipeCategoriesByRecipeId {
          nodes {
            category {
              id
              name
            }
          }
        }
        recipeTagsByRecipeId {
          nodes {
            tag {
              id
              name
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`

function getFilter(searchParams) {
  const filter = {}

  if (searchParams.has('name')) {
    filter['name'] = { likeInsensitive: `%${searchParams.get('name')}%` }
  }

  if (searchParams.has('published')) {
    filter['published'] = { equalTo: searchParams.get('published') === 'true' }
  }

  if (searchParams.has('isHeartFriendly')) {
    filter['isHeartFriendly'] = {
      equalTo: searchParams.get('isHeartFriendly') === 'true',
    }
  }

  if (searchParams.has('recipeCategoriesByRecipeId')) {
    filter['recipeCategoriesByRecipeId'] = {
      some: {
        categoryId: {
          equalTo: +searchParams.get('recipeCategoriesByRecipeId'),
        },
      },
    }
  }

  if (searchParams.has('recipeTagsByRecipeId')) {
    filter['recipeTagsByRecipeId'] = {
      some: {
        tagId: {
          equalTo: +searchParams.get('recipeTagsByRecipeId'),
        },
      },
    }
  }

  return Object.keys(filter).length === 0 ? undefined : filter
}
